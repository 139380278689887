import React from "react"
import Layout from "../template/index"
import SEO from "../utils/seo"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import SiteLink from "../bits/siteLink"

const Title = styled.h5`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
`
const Para = styled.p`
  padding: 15px;
`

const ThankYou = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "form_received.png" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Thanks for Contacting us" />
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Img fixed={data.file.childImageSharp.fixed} />
        <Title>Thanks for Contacting us.</Title>
        <Para>
          We've received your submission, if we are unable to get back within 2 days please get in touch at
          <SiteLink to="mailto:hello&#64;geckogovernance.com">
            {" "}
            <b>hello&#64;geckogovernance.com</b>
          </SiteLink>
        </Para>
      </div>
    </Layout>
  )
}

export default ThankYou
